@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

.loader-wrapper {
	width: 100vh;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.webshop-loader {
	animation: 300ms ease-out 0s 1 slideInFromTop;
	height: 200vh;
	background-color: #272727;
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
}
