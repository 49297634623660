@import './reset.scss';
@import './global.scss';
@import './animations.scss';
@import './text.scss';
@import './cookie-consent.scss';

.sidebar-trigger {
    transition:
        width 0.15s ease-in-out,
        background 0.3s ease !important;

    &--in {
        width: 148px !important;

        &:before {
            content: 'Pogledaj više ';
            font-size: 15px !important;
            margin-right: 14px;
        }
    }
}