.highlight {
    border-bottom: 3px solid transparent;
    position: relative;
    padding: 1px 8px;
    display: inline-block;
    border-radius: 13px;

    &--orange {
        background-color: hsla(35 100% 60% / 30%);
    }

    &--blue {
        background-color: hsla(var(--primary-blue) / 30%);
    }

    &--red {
        background-color: hsla(355 95% 66% / 30%);
    }

    &--green {
        background-color: hsla(136 57% 51% / 30%);
    }
}

.text-color {
    &--orange {
        color: #FFAB35;
    }

    &--blue {
        color: hsl(var(--primary-blue));
    }

    &--red {
        color: #FB5462;
    }

    &--green {
        color: #3BC961;
    }

    &--yellow {
        color: #FFAB35;
    }
}

li ol > li {
    position: relative;
    list-style: none;
    counter-increment: ol1;

    &:before {
        font-weight: 400;
        color: var(--tw-prose-counters);
        content: counter(ol1, lower-alpha) ". ";
        left: -20px;
        position: absolute;
        text-align: right;
    }
}

li ul > li {
    list-style: circle;
}